/* eslint-disable */
<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Websocket to receive Data</v-card-title>
      <v-card-text>Populates and builds form below</v-card-text>
      <v-row>
        <v-card-text>
          <v-card>
            <vue-webrtc
              ref="webrtc"
              width="100%"
              :room-id="roomId"
              socket-u-r-l="https://pharmaguide.ngrok.io"
              @joined-room="logEvent"
              @left-room="logEvent"
              @opened-room="logEvent"
              @share-started="logEvent"
              @share-stopped="logEvent"
              @error="onError"
            />
          </v-card>
        </v-card-text>
        <v-col class="col-md-12 my-3">
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onJoin"
          >
            Join
          </v-btn>
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onLeave"
          >
            Leave
          </v-btn>
          <!-- <button type="button" class="btn btn-primary" @click="onCapture">Capture Photo</button>
            <button type="button" class="btn btn-primary" @click="onShareScreen">Share Screen</button> -->
        </v-col>
      </v-row>
    </v-card>
    <v-tabs v-model="currentTab">
      <v-tab>Pt</v-tab>
      <v-tab>EKG</v-tab>
      <v-tab>LVm,s</v-tab>
      <v-tab>LVd+Atria</v-tab>
      <v-tab>RV</v-tab>
      <v-tab>Ao</v-tab>
      <v-tab>AoV</v-tab>
      <v-tab>MV</v-tab>
      <v-tab>TV-PV</v-tab>
      <v-tab>Peric-Pleura</v-tab>
      <v-tab>Sum</v-tab>
      <v-tab>Unlab</v-tab>
      <v-tab>Ctrl</v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="item in items"
        :key="item.id"
      >
        <v-card-title>
          Tab Title: {{ item.title }} Tab ID#:{{ item.id }}
        </v-card-title>
        <v-card-text flat>
          <FormulateForm
            v-model="formValues"
            :schema="item.schema"
          />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="row justify-center align-center">
      <v-card-actions>
        <v-col>
          <div class="demo-space-x">
            <v-btn
              color="success"
              dark
              @click="generatePdf"
            >
              Download PDF
              <v-icon
                dark
                right
              >
                {{ icons.mdiCloudDownloadOutline }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-row>
  </div>
</template>

<script>
import { mdiCloudDownloadOutline } from '@mdi/js'
import { jsPDF } from 'jspdf'
import { WebRTC } from 'vue-webrtc'
import Vue from 'vue'

Vue.component(WebRTC.name, WebRTC)

/* eslint-disable */
export default {
  name: 'Worksheet',
  data() {
    return {
      img: null,
      roomId: '',
      icons: {
        mdiCloudDownloadOutline,
      },
      formValues: {
        cheese: 0,
      },
      currentTab: 0,
      items: [
        {
          title: 'Pt',
          id: 1,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Pt',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese',
                  label: 'LV Mass & Wall Thickness',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    'Normal LV mass, size & wall Thickness': 'Normal LV mass, size & wall Thickness',
                    'Normal LV mass': 'Normal LV mass',
                    'Proximal Septal thickening': 'Proximal Septal thickening',
                    'Proximal Septal thickenting (mild)': 'Proximal Septal thickenting (mild)',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings',
                  label: 'EF ASE 2015',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'ASE 2015(male)',
                    prosciutto: 'ASE 2015(female)',
                    avocado: 'mild 41-45%',
                    onion: 'mild to moderate ~40%',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
                {
                  label: 'Slider example?',
                  type: 'range',
                  name: 'range',
                  min: '0',
                  max: '100',
                  value: '45',
                  validation: 'required|min:10|max:90',
                  'error-behavior': 'live',
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'EKG',
          id: 2,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'EKG',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size2',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese2',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings2',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code2',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone2',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'LVm,s',
          id: 3,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'LVm,s',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size3',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese3',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings3',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code3',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone3',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'LVd+Atria',
          id: 4,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'LVd+Atria',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size4',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese4',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings4',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code4',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone4',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'RV',
          id: 5,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'RV',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size5',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese5',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings5',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code5',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone5',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'Ao',
          id: 6,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Ao',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size6',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese6',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings6',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code6',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone6',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'AoV',
          id: 7,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'AoV',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size7',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese7',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings7',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code7',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone7',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'MV',
          id: 8,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'MV',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size8',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese8',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings8',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code8',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone8',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'TV-PV',
          id: 9,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'TV-PV',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size9',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese9',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings9',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code9',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone9',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'Peric-Pleura',
          id: 10,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Peric-Pleura',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size10',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese10',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings10',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code10',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone10',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'Sum',
          id: 11,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Sum',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size11',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese11',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings11',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code11',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone11',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'Unlab',
          id: 12,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Unlab',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size12',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese12',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings12',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code12',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone12',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
        {
          title: 'Ctrl',
          id: 13,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Ctrl',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size13',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese13',
                  label: 'Cheese options',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    mozzarella: 'Mozzarella',
                    feta: 'Feta',
                    parmesan: 'Parmesan',
                    extra: 'Extra cheese',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings13',
                  label: 'Toppings',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'Salami',
                    prosciutto: 'Prosciutto',
                    avocado: 'Avocado',
                    onion: 'Onion',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code13',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone13',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
      ],
    }
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.roomId = this.$route.params.roomId
  },
  methods: {
    generatePdf() {
      const doc = new jsPDF()
      const formValues = this.formValues
      doc.addImage('phox.png', 'PNG', 15, 60, 60, 88)
      doc.text('You selected a pizza thats size: ' + formValues.size, 10, 10)
      for (var i = 0; i <= 3; i++) {
        doc.text('You selected the following options ' + formValues.cheese[i], 10, 20 + i * 10)
      }
      doc.save('a4.pdf')
    },
    onCapture() {
      this.img = this.$refs.webrtc.capture()
    },
    onJoin() {
      this.$refs.webrtc.join()
    },
    onLeave() {
      this.$refs.webrtc.leave()
    },
    onShareScreen() {
      this.img = this.$refs.webrtc.shareScreen()
    },
    onError(error, stream) {
      console.log('On Error Event', error, stream)
    },
    logEvent(event) {
      console.log('Event : ', event)
    },
  },
}
</script>
